@import '../../assets/scss/shared';

.aquilana-icon {
  &.color-gray-blue {
    color: $primary-gray-blue;
  }

  &.color-turquoise {
    color: $primary-turquoise;
  }
}

.cancellation-dates-table {
  .ant-table {
    .ant-table-row {
      &.striped-odd {
        background-color: #E2E8EC;
      }

      &.striped-even {
        background-color: #CFDEE4;
      }
    }
  }

  .cancellation-date, .notice-period {
    p {
      margin-bottom: 0;
    }
  }
}

@import '../../assets/scss/shared';

.overview-card {
  @media print {
    page-break-before: auto;
    page-break-inside: avoid;
    padding-top: $spacer;
    border-top: 1px solid $primary-gray-blue;
    display: block;

    .card-header {
      display: none;
    }
  }

  .card-header {
    background: $total-background;
    color: $primary-gray-blue;
    padding: $spacer;
  }

  .card-body {
    background: $white;
    padding: $spacer;

    @media print {
      padding-left: $spacer;
    }

    table {
      width: 100%;

      td {
        vertical-align: top;
      }
    }

    .label {
      text-align: right;
      width: 40%;
      padding-right: $spacer;

      &.medium-large {
        width: 50%;
      }

      &.large {
        width: 100%;
      }
    }

    .currency {
      text-align: right;
      padding-right: $spacer * 0.5;
    }

    .value {
      width: 60%;
      position: relative;

      &.medium-large {
        width: 50%;
      }
    }

    .pre-total {
      td {
        padding-bottom: $spacer;
      }
    }

    .total {
      td {
        padding-top: $spacer;
        border-top: 1px solid $primary-gray-blue;

        @media print {
          border-top: none;
        }
      }
    }
  }
}

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

// Typography
$font-size-root: 20px;
$font-size-base: $font-size-root;
$font-size-small: 0.85rem;
$font-size-extra-small: 0.75rem;
$h1-font-size: 2.6rem;
$h2-font-size: 1.6rem;
$h3-font-size: 1.4rem;
$h4-font-size: 1.2rem;
$h5-font-size: 1.1rem;
$h6-font-size: 1rem;
$lead-font-size: 1.2rem;

// styling
$border-radius: 5px;

$infobox-title-color: #4994ED;
$infobox-border-color: #4994ED;
$infobox-background-color: #E0EEFC;

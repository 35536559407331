@import '../../assets/scss/shared';

.footer {
  background-color: $footer-background;
  color: $white;
  padding: 0;
  border-top: 2px solid $white;

  a,
  button {
    color: $white;
    text-decoration: underline;
  }

  button {
    font-size: 1rem;

    &.button-link {
      color: $white;
    }
  }

  .copy {
    border-top: 1px solid $white;
    padding-top: $spacer*0.5;
    font-size: $font-size-extra-small;
  }
}

.info-text {
  ul:first-of-type {
    li {
      padding-bottom: 0.5rem;

      p {
        white-space: pre-line;
      }
    }
  }
}

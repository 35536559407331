@import '../../assets/scss/colors';

.ant-table-wrapper {
  .ant-table {
    .ant-table-container {
      .ant-table-thead {
        .ant-table-cell {
          background-color: $table-head-bg-color;
          font-weight: 700;
          font-size: 18px;
          color: $white;
          border-bottom: none;
          border-radius: 0;

          &:before {
            background-color: $table-color
          }
        }
      }


      .ant-table-expanded-row {
        background-color: #F1F7F9;

        .ant-table {
          background-color: transparent;

          .ant-table-row {
            &:hover {
              .ant-table-cell {
                background-color: transparent;
              }
            }
          }
        }
      }

      .ant-table-row {
        &.striped-even {
          background: $table-stripe-bg-color;
        }

        &.expired {
          background: $expired-bg;

          &:hover {
            background: $expired-bg-hover;

            > td {
              background: $expired-bg-hover;
            }
          }
        }
      }

      .disabled {
        cursor: not-allowed;
        color: $table-row-font-color-disabled;

        &:hover {
          color: $table-row-font-color-disabled;
        }
      }
    }
  }
}

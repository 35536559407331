.opt-in-form {
  .ant-checkbox-wrapper {
    span {
      text-align: justify;

      p {
        margin-bottom: 0;
      }

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

@import '../../assets/scss/shared';

.ant-select,
.ant-select-item,
.ant-select-selection-item,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  height: inherit;

  .ant-select-item-option-content {
    font-weight: $font-weight-light;
  }

  .ant-select-selection-item {
    font-weight: $font-weight-light;

  }

  span {
    font-weight: $font-weight-light;
  }

  &.small {
    .ant-select-selector {
      font-size: 16px;
    }
  }
}

.ant-select-dropdown {
  &.small {
    .ant-select-item {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

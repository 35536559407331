@import '../../assets/scss/shared';

.aquilana-info {
  .anticon {
    color: $primary-gray-blue;
  }
}

.aquilana-info-modal {
  .anticon {
    color: $primary-gray-blue;
  }

  .content-wrapper {
    padding: $spacer;

    .title {
      padding-top: $spacer;
    }
  }
}

@import '../../assets/scss/shared';

.insurance-card {
  color: $primary-gray-blue;
  font-weight: $font-weight-normal;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.dark {
    color: $white;
    background: $primary-gray-blue;

    .icon-wrapper {
      color: $white;
      cursor: inherit;
    }

    h4 {
      color: $white;
    }

    .divider-primary {
      border-color: $white;
    }

    a {
      color: $white;
    }
  }

  .icon-wrapper {
    text-align: center;
    padding-bottom: $spacer;
    position: relative;

    .anticon {
      color: $primary-turquoise;
    }

    .select-wrapper {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .click-area {
    cursor: pointer;
  }

  .ant-radio-wrapper {
    margin-right: 0;
  }
}

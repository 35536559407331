@import '../assets/scss/shared';

.person-block {
  @media print {
    page-break-before: always;

    .card {
      page-break-before: auto;
      page-break-after: auto;
      page-break-inside: avoid;
      border-top: 1px solid $primary-gray-blue;
      border-radius: 0;
      box-shadow: none;
      position: relative;
      display: block;

      .ant-divider {
        display: none;
      }
    }

    .product-icon {
      position: absolute;
      top: $spacer;
      right: $spacer;
    }

    .price-row {
      text-align: right;
    }
  }

  &.full-details {
    .block-header {
      margin-bottom: 0;
    }

    .person-insurances {
      margin: 0 !important;

      .base-insurance {
        background-color: $white;
        padding: $spacer*1.5 0;
      }

      .addon-insurances {
        background-color: lighten($person-background, 10%);
        padding: $spacer*1.5 0;
      }

      .travel-insurance-wrapper,
      .cancellation-wrapper {
        background-color: $white;
        padding: $spacer*1.5 0;

        .cancellation {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }

  .block-header {
    margin-bottom: $spacer*1.5;

    @media print {
      margin-bottom: 0;
    }

    .person-header {
      background: $person-background;
      color: $primary-gray-blue;
      padding: $spacer;
      display: flex;
      align-items: center;
      gap: $spacer;
      margin-bottom: 0;
    }

    .person-body {
      background: $white;
      color: $primary-gray-blue;
      padding: 0;

      .ant-descriptions-item-label {
        background-color: $white;
        font-weight: $font-weight-light;
      }

      .ant-descriptions-item-content {
        background-color: lighten($person-background, 10%);
        font-weight: $font-weight-normal;
      }
    }

    .name {
      font-size: $h2-font-size;
      color: $primary-gray-blue;
      font-weight: $font-weight-normal;
    }

    .details {
      font-size: 12px;
      color: $primary-gray-blue;
      font-weight: $font-weight-light;
    }

    .edit {
      text-align: center;
    }
  }

  .product-icon {
    text-align: right;
    color: $primary-turquoise;
  }

  .delete {
    text-align: right;
    position: relative;

    @media print {
      display: none;
    }

    .button {
      padding: 0;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    .anticon {
      color: $error;
    }
  }

  .ant-descriptions-item-content,
  .ant-descriptions-item-label {
    width: 50%;
    font-size: $font-size-small;
  }
}

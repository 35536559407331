@import '../../../assets/scss/shared';

.cancellation-card {
  cursor: pointer;
  height: 100%;
  padding: 3 * $spacer;

  &--disabled {
    cursor: not-allowed;
    position: relative;
    overflow: hidden;

    .content {
      opacity: 0.45;
    }

    .corner-text {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      transform: translateX(50%) translateY(-50%) rotate(45deg);
      transform-origin: center;
      z-index: 1;
    }

    .corner-text::before {
      content: '';
      display: block;
      height: 0;
      padding-top: 100%;
    }

    .corner-text > span {
      display: inline-block;
      background: $primary-gray-blue;
      padding: 6px 45px;
      transform: translateY(-50%);
      font-weight: 500;
      text-align: center;
      color: white;
      white-space: nowrap;
    }
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;

    h4 {
      margin-bottom: 1rem;
    }

    .check-list {
      flex: 1;
    }

    .button-wrapper {
      align-self: flex-end;
      display: flex;
      align-items: center;
      color: $primary-gray-blue;
      gap: 0.5rem;

      .aquilana-text {
        font-weight: 500;
      }
    }
  }
}

@import '../../assets/scss/shared';

.info-box {
  border: 1px solid $infobox-border-color;
  border-radius: $border-radius;
  background: $infobox-background-color;
  padding: $spacer;
  color: lighten($infobox-title-color, 10%);
  font-size: $font-size-small;
  font-weight: $font-weight-light;

  .strong {
    color: $infobox-title-color;
    margin-bottom: 0.5rem;
  }

  a,
  .button-link {
    text-decoration: underline;
    color: $infobox-title-color;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

@import '../../assets/scss/shared';

.container {
  display: flex;
  justify-content: center;
  padding: $spacer*2 0;

  &.no-padding {
    padding: 0;
  }

  &.main-content {
    padding: $spacer*3 0 $spacer*4 0;

    @media print {
      padding: 0;
    }
  }

  &.white {
    background-color: $container-bg-white;
  }

  &.light {
    background-color: $container-bg-light;
  }

  &.gray-blue {
    background-color: $container-bg-gray-blue;
  }

  .boxed {
    width: 350px;

    @include media-breakpoint-up(sm) {
      width: 550px;
    }

    @include media-breakpoint-up(lg) {
      width: 970px;
    }

    @include media-breakpoint-up(xl) {
      width: 1200px;
    }

    @media print {
      width: 100%;
    }
  }
}

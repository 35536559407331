@import '../../../../assets/scss/shared';

.product-card {
  cursor: pointer;
  color: $primary-gray-blue;
  font-size: 20px;
  font-weight: $font-weight-normal;

  .anticon {
    color: $primary-turquoise;
  }

  .checkbox-wrapper {
    border-left: 1px solid $primary-gray-blue;
    line-height: 50px;
    padding-left: $spacer !important;
  }
}

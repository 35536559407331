@import '../assets/scss/shared';

.franchise-selector {
  margin-top: 40px;
  margin-left: 25px;
  width: 250px;

  @include media-breakpoint-up(lg) {
    margin-left: 6px;
    width: 300px;
  }

  &.ant-slider,
  .ant-slider:hover {
    .ant-slider-rail,
    .ant-slider-track,
    .ant-slider-step {
      height: 12px;
    }

    .ant-slider-rail {
      border: 1px solid $primary-gray-blue;
      border-radius: $border-radius;
      background-color: $white;
    }

    .ant-slider-dot {
      height: 12px;
      width: 1px;
      border-radius: 0;
      border-width: 0;
      background-color: $primary-gray-blue;
      top: 0;

      &:first-child,
      &:last-child {
        display: none;
      }
    }

    .ant-slider-dot-active {
      background-color: $white;
    }

    .ant-slider-handle {
      width: 12px;
      height: 32px;
      border-radius: $border-radius;
      background-color: $primary-gray-blue;
      margin-top: -11px;

      &::before,
      &::after {
        display: none;
      }
    }

    .ant-slider-track {
      background-color: $primary-turquoise;
      border-radius: $border-radius;
    }

    .ant-slider-mark {
      margin-top: -52px;
    }

    .ant-slider-mark-text {
      &:first-child {
        margin-left: 24px;
      }
      &:last-child {
        margin-left: -30px;
      }
    }
  }
}

.franchise-info {
  padding-top: 20px;
}

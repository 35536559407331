@import '../../../../assets/scss/shared';

.payment-form {
  .select-card {
    .ant-radio-wrapper {
      background: $white;
      width: 100%;
      box-shadow: $box-shadow;
      position: relative;
      padding: $spacer;
      min-height: 5 * $font-size-base;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }

    span {
      flex: 1;
    }

    .ant-radio {
      flex: 0;
      margin-bottom: 6px;
    }
  }

  .payment-info {
    p {
      margin-bottom: 0;
    }
  }
}

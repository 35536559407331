@import '../../assets/scss/shared';

.button {
  border-radius: 30px;
  padding: 0.15rem 1rem;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  line-height: 1.5rem;
  font-size: $font-size-small;

  &:disabled {
    opacity: 0.4;
    cursor: inherit;
  }

  &.button-link {
    border: none;
    border-radius: 0;
    padding: 0;
    background: transparent;
    line-height: 1;
    color: $primary-gray-blue;
    outline: none;
    text-decoration: underline;
    font-weight: $font-weight-light;
  }

  &.primary-gray-blue {
    border-color: $primary-gray-blue;
    background-color: $primary-gray-blue;
    color: $white;

    .anticon-spin {
      color: $white;
    }

    &:hover:not(:disabled) {
      border-color: lighten($primary-gray-blue, $button-hover-lighten);
      background-color: lighten($primary-gray-blue, $button-hover-lighten);
    }

    &:active:not(:disabled) {
      color: $primary-gray-blue;
      background-color: $white;
    }
  }

  &.primary-turquoise {
    border-color: $primary-turquoise;
    background-color: $primary-turquoise;
    color: $white;

    &:hover:not(:disabled) {
      border-color: lighten($primary-turquoise, $button-hover-lighten);
      background-color: lighten($primary-turquoise, $button-hover-lighten);
    }

    &:active:not(:disabled) {
      color: $primary-turquoise;
      background-color: $white;
    }
  }

  &.secondary {
    border-color: $primary-gray-blue;
    background-color: transparent;
    color: $primary-gray-blue;

    &:hover:not(:disabled) {
      border-color: $primary-gray-blue;
      background-color: $primary-gray-blue;
      color: $white;
    }

    &:active:not(:disabled) {
      border-color: lighten($primary-gray-blue, 20%);
      background-color: lighten($primary-gray-blue, 20%);
      color: $secondary-smoke-blue;
    }
  }

  &.danger {
    color: $error;
  }
}

$primary-turquoise: #78BECD;
$primary-gray-blue: #375064;
$primary-light-red: #d799a1;
$secondary-smoke-blue: #B3C0CC;
$secondary-smoke-blue-light: #EFF2F5;
$dark: #000000;
$dark-80: #00000080;
$dark-light: #353839;
$white: #FFFFFF;

$info: #6180BC;
$ok: #488B60;
$warning: #F0BC47;
$error: #D24345;

$container-bg-light: #ECF0F4;
$container-bg-white: $white;
$container-bg-gray-blue: $primary-gray-blue;
$container-bg-smoke-blue: $secondary-smoke-blue;

$header-background: $white;
$footer-background: $primary-gray-blue;

$person-background: #D4E5EC;
$total-background: #A6D2DC;

$spacer: 16px;

$button-hover-lighten: 20%;
$box-shadow: 2px 2px 10px #00000029;

$table-color: #475E70;
$expired-bg: #E7A65433;
$expired-bg-hover: #E7A65440;
$table-stripe-bg-color: #F6F9FC;
$table-head-bg-color: #3C5062;
$table-row-font-color-disabled: #868686;

@import '../../assets/scss/shared';

.splitted-container {
  .white {
    background-color: $container-bg-white;
    color: $primary-gray-blue;
  }

  .light {
    background-color: $container-bg-light;
  }

  .gray-blue {
    background-color: $container-bg-gray-blue;
    color: $white;

    h2 {
      color: $white;
    }
  }

  .smoke-blue {
    background-color: $container-bg-smoke-blue;
    color: $primary-gray-blue;

    h2 {
      color: $primary-gray-blue;
    }

    .anticon {
      color: $white;
    }
  }

  .col-left {
    @include media-breakpoint-up(lg) {
      float: right;
    }
  }

  .col-left,
  .col-right {
    width: 350px;
    padding: $spacer * 2;
    text-align: center;
    margin: 0 auto;

    @include media-breakpoint-up(sm) {
      width: 550px;
    }

    @include media-breakpoint-up(lg) {
      width: calc(970px / 2);
      margin: 0;
    }

    @include media-breakpoint-up(xl) {
      width: calc(1200px / 2);
    }
  }
}

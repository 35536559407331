@import './assets/scss/_fontfaces';
@import './assets/scss/shared';

html,
body {
  font-size: $font-size-root;
  font-weight: $font-weight-light;
  font-feature-settings: "tnum", "tnum";
  font-variant: tabular-nums;

  @media print {
    font-size: $font-size-root*0.8;
  }

  :has(.environment-info) {
    .header {
      top: 40px;
    }

    .ant-layout-content {
      margin-top: 140px
    }
  }
}

.ant-layout {
  min-height: 100vh;
}

.ant-layout-content {
  display: flex;
  flex-direction: column;
  margin-top: 100px;

  @media print {
    margin-top: 0;
  }
}

@media print {
  .ant-space,
  .ant-row,
  .ant-col {
    display: block;
  }

  .ant-col {
    width: 100%;
    max-width: 100%;
  }

  .card {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
    border-top: 1px solid $primary-gray-blue;
    border-radius: 0;
    box-shadow: none;
    position: relative;
  }

  .ant-divider {
    display: none;
  }
}

.print-hidden {
  @media print {
    display: none !important;
  }
}

.print-show {
  display: none;

  @media print {
    display: block;
  }
}

.logo-print {
  margin-bottom: $spacer;
}

h1 {
  font-size: 1.8rem;
  font-weight: bold;
  color: $primary-gray-blue;
  margin: 0 0 $spacer 0;
  padding: 0;

  @include media-breakpoint-up(lg) {
    font-size: $h1-font-size;
  }
}

h2 {
  font-size: 1.3rem;
  font-weight: bold;
  color: $primary-gray-blue;
  margin: 0;
  padding: 0;

  @include media-breakpoint-up(lg) {
    font-size: $h2-font-size;
  }
}

h3 {
  font-size: 1.1rem;
  font-weight: bold;
  color: $primary-turquoise;
  margin: 0;
  padding: 0;

  @include media-breakpoint-up(lg) {
    font-size: $h3-font-size;
  }
}

h4 {
  font-size: 1rem;
  font-weight: bold;
  color: $primary-gray-blue;
  margin: 0;
  padding: 0;

  @include media-breakpoint-up(lg) {
    font-size: $h4-font-size;
  }
}

.text-small {
  font-size: $font-size-small;
}

.text-extra-small {
  font-size: $font-size-extra-small;
}

ul.check-list {
  list-style-type: none;
  position: relative;
  padding-left: 1.5rem;

  li {
    margin-bottom: 0.2rem;

    &:before {
      content: '✓';
      color: $primary-turquoise;
      position: absolute;
      left: 0;
    }

    &::marker {
      color: $primary-turquoise;
    }

    p {
      white-space: pre-line;
    }
  }
}

.ant-form-item {
  padding-bottom: $spacer;

  .optional {
    color: $dark-80;
    font-size: $font-size-small;
  }

  .ant-form-item-explain {
    font-size: $font-size-small;
  }
}

.ant-form-item-label {
  font-weight: $font-weight-normal;
}

.ant-input,
.ant-picker,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-affix-wrapper {
  height: 40px;
  border-radius: 12px;
  border-color: $primary-gray-blue;
  border-width: 1px;
  font-weight: $font-weight-light;
  margin-bottom: 2px;
}

.ant-input-affix-wrapper {
  padding-top: 0;
  padding-bottom: 0;

  .ant-input {
    height: 38px;
  }
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 40px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px;
}

.ant-picker {
  width: 100%;
}

.ant-picker-dropdown {
  font-size: 18px;
}

.ant-picker-input > input {
  font-weight: $font-weight-light;
}

.ant-popover-content {
  font-weight: $font-weight-light;

  .ant-btn {
    font-weight: $font-weight-light;
    border-radius: 30px;
    padding: 0 1rem;
    font-size: $font-size-small;
  }
}

@import '../../assets/scss/shared';

.main-container {
  .ant-row {
    @include media-breakpoint-down(xl) {
      .ant-col:first-child {
        order: 1;
        text-align: center;
      }
    }

    .ant-col:first-child {
      padding: $spacer*2 0
    }

    .ant-col {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

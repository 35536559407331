@import '../../assets/scss/shared';

.aquilana-text {
  font-weight: $font-weight-light;

  &.medium {
    font-weight: $font-weight-normal;
  }

  &.strong {
    font-weight: $font-weight-bold;
  }

  &.size-large {
    font-size: $font-size-base * 1.5;
  }

  &.size-intro {
    line-height: 1.5;
  }

  &.size-extra-small {
    font-size: $font-size-extra-small;
  }

  &.size-small {
    font-size: $font-size-small;
  }

  &.color-gray-blue {
    color: $primary-gray-blue;
  }

  &.color-turquoise {
    color: $primary-turquoise;
  }

  &.color-light-red {
    color: $primary-light-red;
  }

  &.align-left {
    text-align: left;
  }

  &.align-center {
    text-align: center;
  }

  &.align-right {
    text-align: right;
  }

  a {
    text-decoration: underline;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.patient-care-info-table {
  .ant-table {
    .ant-table-row {
      &.striped-odd {
        background-color: #E2E8EC;
      }

      &.striped-even {
        background-color: #CFDEE4;
      }
    }
  }

  p {
    margin-bottom: 0;
  }

  margin-bottom: 0.5rem;
}

@import '../../../../assets/scss/shared';

.side-card {
  padding: 0;
  border-radius: $border-radius;

  .card-header {
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    background: $person-background;
    color: $primary-gray-blue;
    padding: $spacer;
    text-align: center;
  }

  .card-content {
    background: $white;
    padding: 2 * $spacer;
    color: $primary-gray-blue;

    a {
      color: unset;
    }
  }
}

@import '../../assets/scss/shared';

.header {
  background-color: $header-background;
  color: $primary-gray-blue;
  font-weight: $font-weight-light;
  padding: 8px;
  height: 100px;
  border-bottom: 1px solid $container-bg-light;
  position: fixed;
  z-index: 2;
  width: 100%;
  line-height: 1;

  .ant-steps-item-title {
    font-weight: $font-weight-light;
    color: $primary-gray-blue;
  }

  .steps {
    display: flex;
    flex-direction: row;
    position: relative;

    &:before {
      content: '';
      background: $primary-gray-blue;
      width: calc(100% - 50px);
      height: 1px;
      position: absolute;
      top: 30px;
      left: 30px;

      @include media-breakpoint-up(lg) {
        width: calc(100% - (2 * 80px) + 10px);
        left: 80px;
      }
    }

    .step {
      position: relative;
      font-size: 14px;
      font-weight: $font-weight-light;
      overflow: visible;
      top: 25px;
      width: 60px;
      text-align: center;
      line-height: 1;

      span {
        margin-top: 20px;
      }

      @include media-breakpoint-up(lg) {
        width: 140px;
      }

      &:before {
        left: 50%;
        position: absolute;
        border-radius: 50%;
        content: ' ';
        border: 1px solid $primary-gray-blue;
        background: $white;
        height: 11px;
        width: 11px;
      }

      &.done,
      &.active {
        &:before {
          background: $primary-gray-blue;
        }
      }

      &.active {
        font-weight: $font-weight-bold;
      }
    }
  }
}

.dot {
  color: #385064;
}

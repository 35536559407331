@import 'variables';

@font-face {
  font-family: 'gt-walsheim';
  src: url(../fonts/gt-walsheim/gt-walsheim-light.woff2) format('woff2'),
  url(../fonts/gt-walsheim/gt-walsheim-light.woff) format('woff');
  font-weight: $font-weight-light;
  font-style: normal;
}

@font-face {
  font-family: 'gt-walsheim';
  src: url(../fonts/gt-walsheim/gt-walsheim-medium.woff2) format('woff2'),
  url(../fonts/gt-walsheim/gt-walsheim-medium.woff) format('woff');
  font-weight: $font-weight-normal;
  font-style: normal;
}

@font-face {
  font-family: 'gt-walsheim';
  src: url(../fonts/gt-walsheim/gt-walsheim-bold.woff2) format('woff2'),
  url(../fonts/gt-walsheim/gt-walsheim-bold.woff) format('woff');
  font-weight: $font-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: 'gt-walsheim';
  src: url(../fonts/gt-walsheim/gt-walsheim-light-oblique.woff2) format('woff2'),
  url(../fonts/gt-walsheim/gt-walsheim-light-oblique.woff) format('woff');
  font-weight: $font-weight-light;
  font-style: italic;
}

@font-face {
  font-family: 'gt-walsheim';
  src: url(../fonts/gt-walsheim/gt-walsheim-medium-oblique.woff2) format('woff2'),
  url(../fonts/gt-walsheim/gt-walsheim-medium-oblique.woff) format('woff');
  font-weight: $font-weight-normal;
  font-style: italic;
}

@font-face {
  font-family: 'gt-walsheim';
  src: url(../fonts/gt-walsheim/gt-walsheim-bold-oblique.woff2) format('woff2'),
  url(../fonts/gt-walsheim/gt-walsheim-bold-oblique.woff) format('woff');
  font-weight: $font-weight-bold;
  font-style: italic;
}
